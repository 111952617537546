<template>
  <div>
    <div class="vip-type-list" style="font-size:16px">
      <span>{{headerShopName}}-净收入</span>
      <div style="display:flex;align-items:center;">
        <div class="date-box">
          <div class="box-left" @click="changeReqYear(false)">＜</div>
          <div>{{reqYear}}</div>
          <div class="box-right" @click="changeReqYear(true)">＞</div>
        </div>
        <el-button type="success" @click="this.$router.go(-1)">返回</el-button>
      </div>
    </div>
    <main  style="margin:0 20px">
      <div class="vip-type-list" style="font-size:14px">
        <span style="font-size:14px">净收入：{{netIncome.toFixed(2)}}</span>
      </div>
      <div style="padding-top:20px">
        <el-table 
            :data="tableDataOne" 

            :header-cell-style="twoTabHeaderStyle"
            :cell-style="textStyle"
            @cell-click="clickFun"
            :span-method="objectSpanMethod"
          >
            <el-table-column prop="shopName" label="店铺名称" width="150" />
            <el-table-column prop="yearMonth" label="时间" width="150" />
            <el-table-column prop="netIncome" label="净收入" width="120" />
            <el-table-column label="收入">
              <el-table-column prop="cashIncomeSummary" label="现金收入" width="120" />
              <el-table-column prop="backIncomeSummary" label="网银收入" width="120" />
              <el-table-column prop="alipayIncomeSummary" label="支付宝收入" width="120" />
              <el-table-column prop="wechartIncomeSummary" label="微信收入" width="120" />
              <el-table-column prop="kdbScanIncomeSummary" label="扫码收入" width="120" />
              <el-table-column prop="groupIncomeSummary" label="团购收入" width="120" />
              <el-table-column label="收入合计" width="150">
                <template #default="props">
                  <span>{{(Number(props.row.income)).toFixed(2)}}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="支出">
              <el-table-column prop="outlayTotal" label="现金支出" width="120" />
              <el-table-column prop="bankExpend" label="网银支出" width="120" />
              <el-table-column prop="alipayExpend" label="支付宝支出" width="120" />
              <el-table-column prop="wechartExpend" label="微信支出" width="120" />
              <el-table-column prop="kdbHandling" label="手续费" width="120" />
              <el-table-column label="支出合计" width="150">
                <template #default="props">
                  <span>{{(Number(props.row.expend)).toFixed(2)}}</span>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
      </div>
    </main>
    <PrintNoFrom rul='report/yearReportIncomeStatisticsExport.json' :add='"&year="+reqYear+"&shopIds="+shopIds' />
  </div>
</template>

<script>
import {ref} from 'vue'
import {postData} from"@a"
import {useRouter} from 'vue-router'
export default {
  setup(){
    let headerShopName = window.localStorage.getItem('shopName')
    let reqYear = ref(new Date().getFullYear())
    const router = useRouter()
        let shopIds = ref(window.localStorage.getItem('shopIds'))
    const tableDataOne = ref([
    ])
    
    //表格样式
    const twoTabHeaderStyle = (row)=>{
      let style={}
      let text = 'text-align'
      style[text]='center'
      let font = 'font-'
      style[font+'size'] = '14px'
      style.color = '#333333'
      style['font-weight'] = 700
      if(row.column.label === "收入合计" || row.column.label === "收入"){
        style["border-right"] = '1px solid rgb(133 133 133)'
      }
    }
    const textStyle = ({ row, column })=>{
      let style={}
      let text = 'text-'
      style[text + 'align']='center'
      style.color = '#333333'
      let font = 'font-'
      style[font+'size'] = '14px'
      style[font+'weight'] = 700
      if(column.label === '时间' && row.name !== "合计"){
        style[font+'style'] = 'normal'
        // style[font+'size'] = '18px'
        style[text+'decoration'] = 'underline'
        style.color = '#50A0D5'
        style.cursor= 'pointer'
      }
      if(column.label === '现金收入' 
      || column.label === '网银收入' 
      || column.label === '支付宝收入' 
      || column.label === '微信收入' 
      || column.label === '扫码收入' 
      || column.label === '团购收入' 
      || column.label === '收入合计' 
      ){
        style.color = '#FF0000'
      }
      if(column.label === '现金支出'
      ||column.label === '网银支出' 
      ||column.label === '支付宝支出' 
      ||column.label === '微信支出' 
      ||column.label === '手续费' 
      ||column.label === '支出合计' 
      ){
        style.color = '#009900'
      }
      if(column.label === '收入合计'){
        style["border-right"] = '1px solid rgb(133 133 133)'
      }
      if(
        column.label === '充值金额' ||
        column.label === '赠送金额'
      ) style['font-weight'] = 400
      return style
    }
    const clickFun = (row, column)=>{
      if(column.label==="时间" && row.shopName !== "合计"){
        router.push({name:'income',query:{shopName:row.shopName,shopId:row.shopId,time:row.yearMonth}})
      }
    }
        // 格式化表格店铺名称（合并）
    const objectSpanMethod = ()=>{
    // const objectSpanMethod = ({ row, column, rowIndex, columnIndex })=>{
      // if(window.localStorage.getItem('sss')){
      //   console.log( row, column)
      // }
      // if (columnIndex === 0) {
      //   if (rowIndex % 2 === 0) {
      //     return {
      //       rowspan: 2,
      //       colspan: 1,
      //     }
      //   } else {
      //     return {
      //       rowspan: 0,
      //       colspan: 0,
      //     }
      //   }
      // }
    }
    const changeReqYear = (val)=>{
      if(val && reqYear.value < new Date().getFullYear()){
        ++reqYear.value
        infoData()
      }else if(!val && reqYear.value > new Date().getFullYear()-1){
        --reqYear.value
        infoData()
      }
    }
    let netIncome = ref(0)
    const infoData = async()=>{
      try{
        let param = {
          year:reqYear.value,
          shopIds:shopIds.value
        }
        let res = await postData('shop/yearReportStatistics.json',param)
        let lastData = {
          shopName:'合计',
          netIncome : 0,
          cashIncomeSummary : 0,
          backIncomeSummary : 0,
          alipayIncomeSummary : 0,
          wechartIncomeSummary : 0,
          kdbScanIncomeSummary : 0,
          groupIncomeSummary : 0,
          income : 0,
          cashExpend : 0,
          bankExpend : 0,
          alipayExpend : 0,
          wechartExpend : 0,
          outlayTotal:0,
          kdbHandling:0,
          expend: 0,
          }
          netIncome.value = 0
        for(let i = 0;i<res.data.length;i++){
          res.data[i].netIncome =  Number(res.data[i].netIncome).toFixed(2)
          res.data[i].cashIncomeSummary =  Number(res.data[i].cashIncomeSummary).toFixed(2)
          res.data[i].backIncomeSummary =  Number(res.data[i].backIncomeSummary).toFixed(2)
          res.data[i].alipayIncomeSummary =  Number(res.data[i].alipayIncomeSummary).toFixed(2)
          res.data[i].wechartIncomeSummary =  Number(res.data[i].wechartIncomeSummary).toFixed(2)
          res.data[i].groupIncomeSummary =  Number(res.data[i].groupIncomeSummary).toFixed(2)
          res.data[i].income =  Number(res.data[i].income).toFixed(2)
          res.data[i].cashExpend =  Number(res.data[i].cashExpend).toFixed(2)
          res.data[i].bankExpend =  Number(res.data[i].bankExpend).toFixed(2)
          res.data[i].alipayExpend =  Number(res.data[i].alipayExpend).toFixed(2)
          res.data[i].wechartExpend =  Number(res.data[i].wechartExpend).toFixed(2)
          res.data[i].kdbHandling =  Number(res.data[i].kdbHandling).toFixed(2)
          res.data[i].outlayTotal =  Number(res.data[i].outlayTotal).toFixed(2)
          res.data[i].expend =  Number(res.data[i].expend).toFixed(2)
          res.data[i].kdbScanIncomeSummary =  Number(res.data[i].kdbScanIncomeSummary).toFixed(2)

          netIncome.value += Number(res.data[i].netIncome)
          lastData.netIncome += Number(res.data[i].netIncome)
          lastData.cashIncomeSummary += Number(res.data[i].cashIncomeSummary)
          lastData.backIncomeSummary += Number(res.data[i].backIncomeSummary)
          lastData.alipayIncomeSummary += Number(res.data[i].alipayIncomeSummary)
          lastData.wechartIncomeSummary += Number(res.data[i].wechartIncomeSummary)
          lastData.kdbScanIncomeSummary += Number(res.data[i].kdbScanIncomeSummary)
          lastData.groupIncomeSummary += Number(res.data[i].groupIncomeSummary)
          lastData.income += Number(res.data[i].income)
          lastData.cashExpend += Number(res.data[i].cashExpend)
          lastData.bankExpend += Number(res.data[i].bankExpend)
          lastData.alipayExpend += Number(res.data[i].alipayExpend)
          lastData.outlayTotal += Number(res.data[i].outlayTotal)
          lastData.kdbHandling += Number(res.data[i].kdbHandling)
          lastData.wechartExpend += Number(res.data[i].wechartExpend)
          console.log(Number(res.data[i].expend))
          lastData.expend += Number(res.data[i].expend)
        }
        lastData.cashIncomeSummary = lastData.cashIncomeSummary.toFixed(2)
        lastData.netIncome = lastData.netIncome.toFixed(2)
        lastData.kdbHandling = lastData.kdbHandling.toFixed(2)
        lastData.outlayTotal = lastData.outlayTotal.toFixed(2)
        lastData.expend = lastData.expend.toFixed(2)
        res.data.push(lastData)
        tableDataOne.value = res.data
      } catch(err) {
        console.log(err)
      }
    }
    infoData()
    return{
      reqYear,
      changeReqYear,
      tableDataOne,
      twoTabHeaderStyle,
      textStyle,
      clickFun,
      objectSpanMethod,
      headerShopName,
      netIncome,
      shopIds,
    }
  }
}
</script>
<style scoped>
.vip-type-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #D9EFFF;
  height: 65px;
  margin-bottom: 10px;
}
.vip-type-list>span{
  display: flex;
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
}


.date-box{
    width: 274px;
    height: 32px;
    margin-right: 20px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(228, 228, 228, 1);
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box-left{
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  border-right: 1px solid #b4b4b4;
  font-size: 16px;
  line-height: 25px;
  cursor: pointer;
}
.box-right{
  text-align: right;
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  font-size: 16px;
  line-height: 25px;
  border-left: 1px solid #b4b4b4;
  cursor: pointer;
}
</style>